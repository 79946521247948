.ant-btn-primary {
  height: 51px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  line-height: 14px;
  padding: 0 23px;
  background: linear-gradient(348.33deg, #0053BE 8.56%, #009AEC 91.44%);;

  img {
    margin-right: 6px;
    margin-bottom: 4px;
  }

  &:focus,
  &:active,
  &:hover {
    background: linear-gradient(348.33deg, #0053BE 8.56%, #009AEC 91.44%);;
    opacity: 0.8;
  }
}

.ant-popover {
  &-arrow {
    display: none;
  }

  &-inner {
    box-shadow: 0px 16px 64px rgba(31, 47, 70, 0.2);
    border-radius: 5px;
    background: transparent;
  }

  &-inner-content {
    padding: 0;
  }
}

.button-disconnect {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  background: $color_gray;
}

.walletconnect-modal__mobile__toggle {
  display: none !important;
}
