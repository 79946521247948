.header {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: transparent;
  height: 80px;
  display: flex;
  align-items: center;
  font-family: 'Josefin Sans';
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 35px;

    @media (max-width: $breakpoint_mobile_max) {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  .logo-text {
    margin-left: 8px;
  }

  &__right {
    .group-btn {
      background: #1b2041;
      /* blue */

      border: 1px solid #0095e8;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 44px;
      padding: 9px 4px 9px 16px;
    }
    .ant-btn-primary {
      height: 36px;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    padding-left: 0;
    list-style: none;

    .menu-item {
      &:not(:last-child) {
        margin-right: 32px;
      }

      &.highlight {
        a {
          color: $color_white;
        }
      }

      &.coming-soon {
        position: relative;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: $color_white;

        span {
          background: $color_red_robin;
          border-radius: 4px;
          padding: 2px;
          text-transform: uppercase;
          position: absolute;
          width: fit-content;
          left: 0;
          bottom: -22px;
        }
      }

      a {
        color: $color_dove_gray;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .button-menu {
    background: transparent;
    display: flex;
    align-items: center;
    border: none;

    svg path {
      fill: $color_white;
    }

    &::after {
      display: none;
    }
  }

  &__right {
    .nav-item {
      color: $color_white;
      font-weight: 800;
      margin-right: 32px;
    }
  }
}

@media (max-width: $breakpoint_tablet_max) {
  .header {
    &__menu {
      display: none;
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }

  .drawer {
    .header {
      padding-left: 15px;
      padding-right: 15px;
    }

    .button-menu {
      border: 2px solid #252d3f;
      border-radius: 48px;
    }

    .ant-drawer-content {
      background: $color_black;
    }

    .ant-drawer-body {
      padding: 0;
      display: flex;
      flex-flow: wrap column;
    }

    .header {
      position: relative;
      justify-content: center;

      .header__left {
        flex: 1;
        text-align: center;
      }

      .header__right {
        position: absolute;
        right: 24px;
      }
    }

    .body {
      ul {
        padding: 0;
      }
      .ant-btn,
      .nav-item {
        width: calc(100% - 40px);
        margin-top: 1em;
      }

      .nav-item {
        display: block;
        margin: auto;

        a {
          color: $color_white;
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
        }

        &:not(:first-child) {
          margin-top: 32px;
        }
      }
    }

    &__footer {
      position: absolute;
      right: 0;
      width: 100%;
      padding: 0 24px;
      bottom: 40px;

      .social {
        padding-left: 0;
        display: flex;
        justify-content: space-between;
        list-style: none;

        img {
          width: 40px;
        }

        &__icon:not(:last-child) {
          margin-right: 10px;
        }
      }

      .btn-wrapper {
        margin-top: 44px;
      }

      .ant-btn {
        width: 100%;
      }

      .ant-btn-primary {
        margin-top: 16px;
        width: 100%;
        height: 36px;

        &.button-disconnect {
          background: $color_red_robin;
          font-weight: bold;
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
}
