.ant-layout {
  .social {
    list-style: none;
    position: absolute;
    top: 50vh;
    right: 40px;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
    z-index: 2;
    padding: 0;

    img {
      width: 32px;
    }

    &__icon {
      &:not(:first-child) {
        margin-top: 20px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    // @media (max-width: $breakpoint_mobile_max) {
    //   left: 50%;
    //   transform: translateX(-50%);
    //   bottom: 56px;
    // }
  }
}

.statistic {
  width: 100%;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 70px;
  &__content {
    width: 100%;
    text-align: center;
  }

  &__right {
    background-image: radial-gradient(61.44% 61.44% at 50% 0%, #022a6e 0%, #10184e 100%);
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    background-blend-mode: overlay, normal;
    opacity: 0.9;
    box-shadow: 2.75903e-15px 45.0583px 90.1167px rgba(3, 12, 61, 0.541);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 46px;
  }
  &__rectangle {
    position: absolute;
    width: 106px;
    height: 106px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 38px;
    color: $color_white;
    font-family: 'Josefin Sans';
    p {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__value {
    color: $color_white;
    font-weight: 700;
    font-size: 30px;
    // line-height: 28px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 65px;
    font-family: 'Josefin Sans';
    padding: 0px 20px;
    p {
      line-height: 24px;
      font-size: 24px;
      color: #7c9ac2;
    }
  }

  .ant-progress-steps-item {
    width: 10px !important;
    height: 18px !important;
    margin-right: 4px;
  }

  &-card {
    display: flex;
    align-items: center;

    .statistic {
      &__right {
        margin-top: 0;
        background-image: none;
        width: fit-content;
        height: auto;
        padding-bottom: 0px;
        box-shadow: none;
      }

      &__title {
        font-weight: 700;
        color: #a9c1e1;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 3px;
        text-shadow: 0px 2px 4px rgba(3, 12, 61, 0.5);
      }

      &__value {
        text-align: left;
        color: $color_white;
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        margin-top: 3px;
        padding: 0px;
      }
      &__content {
        text-align: left;
      }
    }
  }
}

.wrong-network-modal {
  font-family: 'Josefin Sans';
  .ant-modal-content {
    background-color: $color_black;
    border-radius: 10px;
  }

  .ant-modal-body {
    padding-top: 64px;
    padding-bottom: 60px;
    text-align: center;
  }

  .anticon-spin {
    font-size: 80px !important;

    path {
      fill: $color_white;
    }
  }

  .title {
    margin-top: 34px;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $color_white;
  }

  .description {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: $color_white;
  }
  .switch-network {
    margin-top: 1rem;
  }

  .ant-space {
    .ant-btn {
      width: 100%;
    }
  }
}
.ant-modal-mask {
  background: #020931;
  opacity: 0.95;
}
.connect-wallet-modal {
  .ant-modal-content {
    background: radial-gradient(61.44% 61.44% at 50% 0%, #022a6e 0%, #10184e 100%);
    border-radius: 32px;
    box-shadow: 2.75903e-15px 45.0583px 90.1167px rgba(3, 12, 61, 0.541);
    // background-blend-mode: overlay, normal;
  }

  .ant-modal-body {
    padding-top: 64px;
    padding-bottom: 60px;
    text-align: center;
  }

  .anticon-spin {
    font-size: 80px !important;

    path {
      fill: $color_white;
    }
  }

  .ant-modal-close-icon {
    path {
      fill: $color_white;
    }
  }

  .title {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $color_white;
    margin-bottom: 0;
  }

  .description {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: $color_white;
    img {
      margin-top: 80px;
      margin-bottom: 96px;
    }
    &__first {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    &__second {
      font-weight: 400;
      font-size: 18px;
      line-height: 19.8px;
      color: #a9c1e1;
    }
  }

  .ant-space {
    margin-top: 32px;
    width: 100%;
    max-width: 230px;

    .ant-btn {
      width: 100%;
      height: 60px;
      border-radius: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      border: none;
      color: $color_white;
      background: linear-gradient(348.33deg, #0053be 8.56%, #009aec 91.44%);
      text-align: left;
    }
  }
  .icon__left {
    width: 30px;
    height: 50px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.round {
  position: relative;
  background: radial-gradient(80.95% 150.87% at 1.59% 2.08%, #022a6e 30.46%, #10184e 90.05%);
  backdrop-filter: blur(20px);
  border-radius: 32px;
  background-blend-mode: overlay, normal;
  padding: 36px 53px;
  box-shadow: 2.75903e-15px 45.0583px 90.1167px rgba(3, 12, 61, 0.541);
  font-family: 'Josefin Sans';
  @media (max-width: $breakpoint_mobile_max) {
    padding: 50px;

    &.current-coutdown {
      // padding: 50px 10px;
    }
  }

  &__title {
    color: $color_white;
    font-weight: 700;
    font-size: 36px;
    line-height: 29px;
    margin-bottom: 56px;
  }

  @media (max-width: $breakpoint_mobile_max) {
    &.current-coutdown {
      &::before {
        display: none;
      }

      & + .round__bottom {
        .countdown-wrapper {
          flex: 1;

          li {
            padding: 10px;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    margin-top: 44px;

    button {
      background: linear-gradient(348.33deg, #0053be 8.56%, #009aec 91.44%);
      border-radius: 8px;
      height: 60px;
      width: 180px;
    }
  }

  .ant-row {
    margin-top: 0;
  }

  .ant-progress-steps-item {
    width: 6px !important;
    height: 10px !important;
    margin-right: 2px;
  }
}

.big-icon {
  padding: 0.5em;
  border: 2px solid $color_bright_turquoise;
  background-color: transparent;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  overflow: hidden;

  &__container {
    display: flex;
    flex-flow: wrap column;
    align-items: center;
    justify-content: center;
    background-color: $color_tangaroa;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap column;
  }

  &__title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    color: $color_white;
    text-shadow: 2px 0px 0px $color_heliotrope;
    text-align: center;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: $color_alto;
    text-align: center;
    max-width: 150px;
  }
}

.box-item {
  color: $color_alto;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 24px;
  background: conic-gradient(
    from 180deg at 50% 0%,
    #1d0070 0deg,
    #1c006d 135deg,
    #260080 240deg,
    #210967 337.5deg,
    #1d0070 360deg
  );
  box-shadow: 4px 4px 0px #00fbff;
}

.modal-claim-vesting {
  .ant-modal-content {
    background: radial-gradient(61.44% 61.44% at 50% 0%, #022a6e 0%, #10184e 100%);
    background-blend-mode: overlay, normal;
    box-shadow: 2.75903e-15px 45.0583px 90.1167px rgba(3, 12, 61, 0.541);
    border-radius: 32px;
    color: $color_white;
    text-align: center;
    .ant-modal-body {
      padding: 42px;
    }
    .modal-header {
      h3 {
        color: $color_white;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        text-shadow: 0px 2px 4px rgba(3, 12, 61, 0.5);
      }
      img {
        margin: 80px 0px 96px 0px;
      }
    }
    .modal-body {
      h4 {
        color: $color_white;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-shadow: 0px 2px 4px rgba(3, 12, 61, 0.5);
        margin-bottom: 24px;
      }
      p {
        color: #a9c1e1;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        text-shadow: 0px 2px 4px rgba(3, 12, 61, 0.5);
      }
      button {
        width: 243px;
        height: 60px;
        margin-top: 45px;
        border: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        color: $color_white;
        background: linear-gradient(348.33deg, #0053be 8.56%, #009aec 91.44%);
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .box-item {
    font-size: 12px;
    line-height: 20px;
    padding: 12px;
  }

  .big-icon {
    width: 100%;
    height: 100%;
    border: none;
    overflow: unset;

    &__container {
      background-color: transparent;
      flex-flow: row;
      justify-content: flex-start;
    }

    &__image {
      margin-right: 1em;
      width: 60px;
      height: 60px;
      object-fit: contain;
      background-color: $color_tangaroa;
      border: 3px solid $color_bright_turquoise;
      border-radius: 50%;
      padding: 0.4em;
    }

    &__content {
      align-items: flex-start;
    }

    &__title {
      text-align: left;
    }

    &__subtitle {
      max-width: 100%;
      text-align: left;
    }
  }
}
