@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap');

@import 'variables';
@import 'overrides';
@import 'common';
@import 'layout';
@import 'fonts';

/* Components */
@import 'header';
@import 'footer';
@import 'components';

/* Pages */
@import 'home.scss';
@import 'howItWorks.scss';

html,
body {
  font-family: 'Work Sans', sans-serif;
  width: 100% !important;
}
