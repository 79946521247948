.footer {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  font-family: 'Josefin Sans';
  .container {
    text-align: center;
  }

  .copyright {
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #7C9AC2;
    padding: 6px 0;
  }
}
