html,
body {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;

  h4,
  h5,
  ul,
  p {
    margin-bottom: 0;
  }
}

.container {
  max-width: 1290px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.button-connected {
  color: $color_white;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  padding: 0 33px;
  height: 36px;
  background: linear-gradient(348.33deg, #0053BE 8.56%, #009AEC 91.44%);
  border-radius: 6px;

  &:hover,
  &:focus {
    color: $color_white;
    background: linear-gradient(348.33deg, #0053BE 8.56%, #009AEC 91.44%);
    opacity: 0.8;
  }
}

.highlight {
  color: $color_blue;
}

@media (max-width: $breakpoint_mobile_max) {
  .container {
    max-width: 1290px;
  }
}
