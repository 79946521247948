.background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.home {
  background: #020a32;
  height: auto;
  background-size: cover;

  // &.logined {
  //   // background: url(../resources/images/background-logined.jpg) top center
  //   //   no-repeat;

  // }

  @media (max-width: $breakpoint_desktop_max) {
    &.logined {
      background-image: url(../resources/images/background-mobile-logined.jpg);
    }
  }

  @media (max-width: $breakpoint_mobile_max) {
    background-image: url(../resources/images/background-mobile.jpg);
  }

  .banner {
    display: flex;
    align-items: center;
    padding-top: 234px;
    padding-bottom: 292px;
    text-align: center;

    .title {
      font-family: 'Josefin Sans';
      color: $color_white;
      font-size: 64px;
      line-height: 52px;
      text-transform: uppercase;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 24px auto 0;
      color: $color_white;
      max-width: 500px;
    }

    .statistic {
      display: flex;
      flex-flow: wrap column-reverse;
      align-items: center;

      // img {
      //   display: none;
      // }

      &-wrapper {
        // background: rgba(27, 20, 69, 0.2);
        // backdrop-filter: blur(30px);
        // border-radius: 12px;
        // padding: 44px 69px;
        // max-width: 1018px;
        // margin: 0 auto;
        // border: 1px solid #244767;

        // @media (max-width: $breakpoint_mobile_max) {
        //   padding: 44px;
        // }
        & .smoke-car-svg {
          position: absolute;
          transform: scale(0.9) translateX(-15%) translateY(-20%);
          left: 0;
          right: 0;
        }
        // & .smoke-svg,
        // .car1-svg,
        // .car2-svg {
        //   position: absolute;
        //   left: 0;
        //   right: 0;
        //   margin-left: 0;
        //   margin-right: 0;
        //   text-align: center;
        // }
        // & .smoke-svg {
        //   transform: translateX(-15%) scale(0.9);
        // }
      }
    }

    .ant-progress {
      margin-top: 16px;
    }

    @media (max-width: $breakpoint_mobile_max) {
      padding-bottom: 82px;
      padding-top: 204px;
    }
  }

  .banner-guest {
    flex-flow: wrap column;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 100%;

    .title {
      padding-left: 0;
    }

    .ant-btn-primary {
      margin-top: 36px;
      color: $color_white;
    }
  }

  .button-claim {
    font-size: 18px;
    line-height: 18px;
    &[disabled] {
      background-color: $color_cadet_blue;
      color: $color_white;
    }
  }

  .section-details {
    padding-top: 234px;
    padding-bottom: 157px;

    .background-white {
      padding-top: 48px;
      padding-bottom: 48px;
      max-width: calc(100% - 70px);
      width: 100%;
      background-color: rgba(232, 230, 230, 0.7);
      margin: 0 auto;
    }

    .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: $color_white;
      text-align: center;
      text-shadow: 0px 2px 4px rgba(3, 12, 61, 0.5);
    }

    .ant-space {
      display: flex;
      justify-content: center;
      margin-top: 18px;
    }

    .list-round {
      padding: 60px 0 0;
    }

    .switch {
      .ant-switch {
        height: 28px;
        min-width: 52px;

        &-handle {
          width: 24px;
          height: 24px;

          &::before {
            border-radius: 50%;
          }
        }
      }

      .ant-switch-checked {
        background-color: $color_blue;

        .ant-switch-handle {
          left: calc(100% - 24px - 2px);
          &::before {
            background-color: $color_alto;
          }
        }
      }

      &__label {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $color_woodsmoke;
      }
    }

    .statistic__title {
      & + .countdown {
        height: auto;
        justify-content: flex-start;

        li:first-of-type {
          padding: 0 15px 0 0;
        }

        li:not(:first-of-type) {
          padding: 0 15px;
        }
      }
    }

    .countdown {
      text-align: center;
      color: $color_white;
      list-style: none;
      padding-left: 0;
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      align-items: center;
      height: 63px;
      position: relative;

      &-wrapper {
        background: url('../resources/images/countdown.png') center center no-repeat;
        background-size: contain;
        padding-left: 30px;
        padding-right: 30px;
      }

      li {
        padding: 15px;
      }
    }
  }
}

@media (max-width: $breakpoint_tablet_max) {
  .ant-layout {
    .social {
      display: flex;
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
      left: 0;
      right: auto;
      justify-content: space-between;
      top: auto;
      bottom: 20px;

      &__icon:not(:first-child) {
        margin-left: 10px;
        margin-top: 0;
      }

      img {
        width: 40px;
      }
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .home {
    .banner {
      .title {
        font-size: 64px;
        line-height: 52px;
      }

      .description {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
      }

      .statistic {
        .ant-progress-steps-item {
          width: 8px !important;
          height: 16px !important;
        }
      }
    }

    .banner-guest {
      .title {
        font-size: 64px;
        line-height: 52px;
      }

      .description {
        font-size: 14px;
        line-height: 21px;
      }
    }

    .section-details {
      .background-white {
        max-width: 100%;
      }

      .title {
        font-size: 36px;
        line-height: 40px;
      }

      .round {
        .statistic {
          justify-content: flex-start;
          column-gap: 8px;
        }
      }
    }
  }
}

// @media (max-width: $breakpoint_tablet_max) {
//   .home {
//     background-image: url(../resources/images/background-mobile.jpg);

//     &.logined {
//       background-image: url(../resources/images/background-mobile-logined.jpg);
//     }
//   }
// }
